<template>
  <div class="confirm-email">

    <div class="row justify-content-center">
      <div class="col-12 col-md-8 text-center">
        <h1 class="page-title">Confirm your Email</h1>

        <MailIcon />

        <h3>We sent you a email to {{ email }}</h3>

        <p>
          Confirm your e-mail to check out.
        </p>

        <a href="/validate_email/">
          Send another confirmation
        </a>

      </div>
    </div>
  </div>
</template>

<script>
import MailIcon from "@/assets/icons/mail.svg";
export default {
  name: 'ConfirmEmail',
  metaInfo: {
    title: 'Check your email',
  },
  components: {
    MailIcon
  },
  data() {
    return {
      email: '',
      confirmation_token: ''
    }
  },
  mounted() {
    this.initialize()
  },
  methods: {
    initialize() {
      this.email = this.$route.params.email;    
    },
    onFormSubmit() {
      this.$router.push(`/cart/review`);
    },
    onClickCreateAccount() {
      this.$router.push(`/register`);
    },
    
  }
}
</script>

<style lang="scss">
.confirm-email {
  .page-title {
    margin: 6rem 0 55px;
    font-size: 47px;
    font-weight: 700;
  }

  h3 {
    font-weight: normal;
    font-size: 29px;
    margin-bottom: 20px;
  }

  svg {
    margin-bottom: 40px;
  }

  p {
    font-weight: normal;
    font-size: 18px;
    line-height: 150%;
    margin-bottom: 40px;
  }

  a {
    font-size: 18px;
  }

}
</style>
